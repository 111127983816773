import FlashLib from 'flashlib_onlyplay';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import eSoundType from '../../sounds/eSoundType';
import { TweenMax, TimelineLite } from 'gsap';

export default class ControllerJackpotGame extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.visible = false;
    this.alpha = EntryPoint.GameModel.isJackpotGame ? 1 : 0;

    this.init();
    this.addListeners()
  }
  init() {
    this.background = this.getChildByName('background');
    this.totalWinPanel = this.getChildByName('totalWinPanel');
    this.panels = this.getChildByName('panels');
    this.field = this.getChildByName('field');
    this.field.init(this.panels);
    this.interactive = true;
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
  }

  onResize (data) {
    if (data.isMobile && data.isLandscape) {
      this.background.goToFrame(2)
      this.totalWinPanel.anchorDefaultX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1)
      this.totalWinPanel.centringX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y + 200;
    }
  }

  show() {
    this.panels.reset();
    this.field.create();
    this.visible = true;
    this.playJackpotBackgroundSound()
    TweenMax.to(this, 0.6, { alpha: 1 });
  }

  playJackpotBackgroundSound () {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const jackpotBackground = SoundManager.getSound(eSoundType.EST_JACKPOT_GAME_BACKGROUND);
    const tween  = new TimelineLite()
    tween
      .to(backgroundMainGameSound, 0.5, { volume: 0})
      .call(() => {
        SoundManager.pause(eSoundType.EST_BACKGROUND)
        SoundManager.play(eSoundType.EST_JACKPOT_GAME_BACKGROUND, 0.9, true, 'music');
      })
      .fromTo(jackpotBackground, 1.5, {volume: 0}, {volume: 0.9})
  }

  stopJackpotBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const jackpotBackground = SoundManager.getSound(eSoundType.EST_JACKPOT_GAME_BACKGROUND);
    const tween  = new TimelineLite()
    tween
      .to(jackpotBackground, 0.5, { volume: 0})
      .call(() => {
        SoundManager.stop(eSoundType.EST_JACKPOT_GAME_BACKGROUND)
        SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
      })
      .fromTo(backgroundMainGameSound, 1.5, {volume: 0}, {volume: 0.5})
  }

  hide() {
    this.stopJackpotBackgroundSound()
    TweenMax.to(this, 0.6, { alpha: 0, onComplete: () => this.visible = false });
  }
}
