import FlashLib from 'flashlib_onlyplay';
import { wonJackpotGameFontStyle } from '../../fontStyles';
import { TweenMax } from 'gsap/TweenMax';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eServerClientStone from './eServerClientStone';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class PopupWonJackpot extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.alpha = 0;
    this.visible = false;
    this.interactive = true;
    this.jackpotText = this.getChildByName('popup_won_jackpot_jackpot');
    this.jackpotAmount = this.getChildByName('jackpotAmount');
    this.bgAnimation = this.getChildByName('bgAnimation');
    this.background = this.getChildByName('background');
    this.jackpotTweenAmount = 0;
    this.setTextsSyle();
    this.addListeners()
  }

  addListeners () {
    GlobalDispatcher.add('panel: maxIcons', this.show, this);
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
  }

  onGameResized(data) {
    if (data.isMobile && data.isLandscape) {
      this.background.goToFrame(2);
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
    }
  }

  setTextsSyle() {
    this.jackpotText.style = { ...this.jackpotText.style, ...wonJackpotGameFontStyle}
    this.jackpotAmount.style = { ...this.jackpotAmount.style, ...wonJackpotGameFontStyle}
  }

  async show() {
    this.visible = true;
    await this.bgAnimation.start();
    TweenMax.to(this, 0.6, { alpha: 1});

    this.jackpotEndValue = EntryPoint.GameModel.jackpotWinAmount;

    this.jackpotText.text = window.OPWrapperService.localizations.getLocalizedText(`popup_won_jackpot_${eServerClientStone[EntryPoint.GameModel.winJackpotType]}`)

    this.decimals = Math.min(this.jackpotEndValue.getPrecision(), EntryPoint.GameModel.currencyInfo.decimals);
    const arrForDecimals = MoneyFormat.formatCurrency(this.jackpotEndValue, this.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;

    TweenMax.fromTo(this, 3,{ jackpotTweenAmount: 0}, { jackpotTweenAmount: this.jackpotEndValue, onComplete:() => {
        this.jackpotTweenAmount = 0;
        this.jackpotAmount.text = `${MoneyFormat.formatCurrency(this.jackpotEndValue, this.decimals)} ${EntryPoint.GameModel.currencyInfo.currency}`
        setTimeout(this.hide.bind(this), 2000)
      }});
    SoundManager.play(eSoundType.EST_JACKPOT_GAME_JACKPOT, 0.7, false, 'winning');
  }

  set jackpotTweenAmount(value) {
    this._jackpotTweenAmount = value;
    this.jackpotAmount.text = `${(this._jackpotTweenAmount ).toFixed(this.decimalsForAnimation)} ${EntryPoint.GameModel.currencyInfo.currency}`
  }

  get jackpotTweenAmount() {
    return this._jackpotTweenAmount;
  }
  hide(){
    StatesManager.goToNextState();
    TweenMax.to(this, 0.6, { alpha: 0, onComplete: () => {
        this.bgAnimation.stop();
        this.visible = false
      }});
  }
}
