import FlashLib from 'flashlib_onlyplay';
import { TweenMax } from 'gsap/all';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class BasePopup extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.visible = false;
    this.addListeners();
  }
  init() {
    this.bgAnimation = this.getChildByName('bgAnimation');
    this.background = this.getChildByName('background');
  }
  addListeners() {
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
  }

  onGameResized(data) {
    if (data.isMobile && data.isLandscape) {
      this.background.goToFrame(2);
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
    }
  }

  async show() {
    await this.bgAnimation.start();
    this.visible = true;
    TweenMax.to(this, 0.6, {alpha: 1})
    this.bgAnimation.start();
    this.onShow();
  }

  hide() {
    TweenMax.to(this, 0.3, {alpha: 0, onComplete: () => {
        this.visible = false;
        this.bgAnimation.stop();
        this.onHide();
      }})
  }

  onShow() {

  }

  onHide() {

  }
}
